export enum PAGE_ROUTES {
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot-password',
  CREATE_ACCOUNT = 'create-account',
  BRAND = 'brand',
  CONTENT_LIBRARY = 'content-library',
  LOCATIONS = 'locations',
  REPORTS = 'reports',
  LOYALTY_PROGRAM = 'loyalty-program',
  MARKETING_CAMPAIGNS = 'marketing-campaigns',
  USERS = 'users',
  WALLET = 'wallet',
}
